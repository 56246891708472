<template>
    <v-layout>
        <v-snackbar
            v-model="snackBar.show"
            :timeout="snackBar.timeout"
            :variant="snackBar.variant"
            :color="snackBar.color"
            :location="snackBar.location"
            vertical
            multi-line
        >
            <p v-html="snackBar.text"></p>
        </v-snackbar>
        <NavigationDrawer />
        <ContentContainer />
        <BottomNav v-if="$mobile"/>
        <v-fab
            icon="mdi-theme-light-dark"
            :size="$mobile ? 56 : 70"
            :style="$mobile ? 'position: fixed; bottom: 45px; right: 20px;' : 'position: fixed; bottom: 20px; right: 20px;'"
            app
            appear
            @click="toogleTheme"
        ></v-fab>
    </v-layout>
</template>

<script>
import NavigationDrawer from './NavigationDrawer.vue'
import ContentContainer from './ContentContainer.vue'
import BottomNav from './BottomNav.vue'
import { useTheme } from 'vuetify'
import { useMain } from '@/stores/main'
import { storeToRefs } from 'pinia'
import { getCurrentInstance, markRaw } from 'vue'

export default markRaw({
    components: {
        NavigationDrawer,
        ContentContainer,
        BottomNav
    },
    setup() {
        const theme = useTheme()
        const mainStoreRefs = storeToRefs(useMain())
        const { snackBar } = mainStoreRefs
        const internalInstance = getCurrentInstance()
        const $mobile = internalInstance.appContext.config.globalProperties.$mobile

        // change zoom level
        if ($mobile){
            document.body.style.zoom = '90%'
        }
        const toogleTheme = () => {
            localStorage.setItem('theme', theme.global.current.value.dark ? 'light' : 'dark')
            theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
        }
        return { toogleTheme, snackBar }
    }
})
</script>
