<template>
    <v-layout>
        <v-main class="d-flex align-center justify-center" style="min-height: 300px;">
            <router-view style="z-index: 1"></router-view>
            <v-fab
                icon="mdi-theme-light-dark"
                :size="$mobile ? 56 : 70"
                style="position: fixed; bottom: 5px; right: 20px;"
                app
                appear
                @click="toogleTheme"
            ></v-fab>
        </v-main>
    </v-layout>
</template>

<script>
import { useTheme } from 'vuetify'

export default {
    setup() {
        const theme = useTheme()
        const toogleTheme = () => {
            localStorage.setItem('theme', theme.global.current.value.dark ? 'light' : 'dark')
            theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
        }
        return { toogleTheme }
    }
}
</script>
