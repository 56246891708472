<template>
  <v-app>
      <component :is="currentLayout"></component>
  </v-app>
</template>

<script setup>
  import MainContainer from './components/BasicWebLayout/MainContainer.vue'
  import BlankLayout from './components/BlankLayout/BlankLayout.vue'
  import { useRouter } from 'vue-router'
  import { ref } from 'vue'

  const router = useRouter()
  const currentLayout = ref(null)
  const BLANK_LAYOUT_ROUTES = ['login', 'landing']

  router.beforeEach((to, from, next) => {
    if (!BLANK_LAYOUT_ROUTES.includes(to.name)) {
      currentLayout.value = MainContainer
    } else {
      currentLayout.value = BlankLayout
    }
    next()
  })
</script>

<style>
#app {
  font-family: Roboto, sans-serif;
  --webkit-font-smoothing: antialiased;
}

.v-card {
    border-radius: 10px !important;
}

.v-dialog {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
}

.v-dialog::-webkit-scrollbar {
    display: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

::-webkit-scrollbar-track {
    background: #ffffff;
    border-left: 0px solid #ffffff;
}

::-webkit-scrollbar-thumb {
    background: #369188;
    border: solid 0px #e6e6e6;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb#369188;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232300;
}

@media (max-width: 600px) {
    body {
        overflow: hidden !important;
        max-height: 100vh !important;
        overflow-y: scroll !important;
    }

    ::-webkit-scrollbar {
        width: 1px !important;
        height: 1px !important;
    }
}
</style>
