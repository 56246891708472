<template>
    <v-footer
        name="footer"
        app
        grow
        style="position: fixed; bottom: -75px; width: 100%; "
    >        
        <v-bottom-navigation
            v-model="value"
            color="primary"
            mode="shoft"
        >
            <v-btn
                v-for="(element, i) in elements.filter(item => hasAccess(item.roles, item.groups))"
                :key="i"
                :value="element.value"
                link
                :to="{ name: element.to }"
            >
                <v-icon>{{ element.icon }}</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-footer>
</template>

<script>
import { ref } from 'vue'
import { uselogin } from '@/stores/login'
import { mapActions } from 'pinia'
import { ADMIN, USER } from '@/util'

export default {
    setup() {
        const value = ref(0)
        const elements = [
            {
                icon: 'mdi-account-file-text-outline',
                title: 'My Profile',
                value: 'profile',
                to: 'profile',
                groups: [],
                roles: []
            },
            { 
                icon: 'mdi-calendar-multiselect', 
                title: 'Home', 
                value: 'home', 
                to: 'home',
                groups: [],
                roles: []
            },
            { 
                icon: 'mdi-google-classroom', 
                title: 'Lessons', 
                value: 'lessons', 
                to: 'lessons',
                groups: [],
                roles: [ADMIN]
            },
            { 
                icon: 'mdi-security', 
                title: 'Administration', 
                value: 'administration', 
                to: 'administration',
                groups: [],
                roles: [ADMIN]
            },
            { 
                icon: 'mdi-cash-sync', 
                title: 'Payment', 
                value: 'payment', 
                to: 'payment',
                groups: [],
                roles: [USER]
            },
        ]
        return { value, elements }
    },
    methods: {
        ...mapActions(uselogin, ['hasAccess'])
    },
}
</script>