export default {
    dark: false,
    colors: {
        "background": '#fcf5ed',
        "surface": '#fcf2e6',
        'surface-bright': '#fcf5ed',
        'surface-light': '#C4C4C4',
        'surface-variant': '#D5D5D5',
        'on-surface-variant': '#121212',
        "primary-lighten-2": "#6DD3C7",
        "primary-lighten-1": '#42B3A8',
        "primary": '#7a9e7f',
        "primary-darken-1": "#296F68",
        'primary-darken-2': '#1D4D48',
        "secondary-lighten-2": "#A4D3F5",
        "secondary-lighten-1": '#6EBDF5',
        "secondary": '#4CBDF5',
        'secondary-darken-1': '#4C94F5',
        "secondary-darken-2": '#2E69AD',
        "error": '#d40000',
        "info": '#0059A6',
        "success": '#039600',
        "warning": '#ff6200',
    }
}