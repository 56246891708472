import { createApp } from 'vue'
import axios from 'axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import pinia from './plugins/pinia'
import router from './plugins/router'
import { uselogin } from '@/stores/login'
import { getBoolLocalStorage } from '@/util'

const app = createApp(App)

////////// REDIRECT TO HTTPS SITE //////////

if (window.location.protocol === 'http:' && process.env.NODE_ENV === 'production') {
    window.location = window.location.href.replace('http:', 'https:')
}

///////////////// PRELOAD /////////////////
const client = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_API_PORT ? `http://localhost:${process.env.VUE_APP_BACKEND_API_PORT}` : "",
    withCredentials: true

})
app.use(pinia)

////////////// PINIA CONFIGURATION //////////////
pinia.use(({ store }) => {
    store.$axios = client
    store.$mobile = window.innerWidth < 600
})

/////////////// LOAD LOGIN STORE ///////////////
const loginStore = uselogin()
loginStore.loadUserInfo()

////////////// ROUTER CONFIGURATION ////////////
router.beforeEach((to, from, next) => {
    const allowedGroups = new Set(to.meta.allowedGroups)
    const allowedRoles = new Set(to.meta.allowedRoles)

    if ((to.meta.requiresAuth && !getBoolLocalStorage('isAuthenticated')) || 
        (!loginStore.hasAccess(allowedRoles, allowedGroups)) &&
        to.name !== 'login') {

        loginStore.logout()
        next({ name: 'login' })
    }  else {
        next()
    }
})
////////////// AXIOS CONFIGURATION //////////////
const LOGIN_PATH = '/api/token/'
const REFRESH_PATH = '/api/token/refresh/'

client.interceptors.response.use(
    response => response,
    async error => {
        const SUCCESS = 200
        const UNAUTHORIZED = 401

        if (error?.response?.status === UNAUTHORIZED && ![LOGIN_PATH, REFRESH_PATH].includes(error.config.url)) {
            try {
                const response = await client.post(REFRESH_PATH)
                if (response?.status === SUCCESS) {
                    return client(error.config)
                } else {
                    loginStore.logout()
                    window.location = '/login'
                }
            } catch (e) {
                loginStore.logout()
                window.location = '/login'
            }
        }

        return error
    }
)
///////// PLUGIN CONFIGURATION //////////
app.use(vuetify)
app.use(router)
////////////// GLOBAL CONFIGURATION ////////////
app.config.globalProperties.$axios = client
app.config.globalProperties.$mobile = window.innerWidth < 760
////////////// APP MOUNTING //////////////
app.mount('#app')