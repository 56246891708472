export default {
    dark: true,
    colors: {
        "background": '#121212',
        "surface": '#202020',
        'surface-bright': '#121212',
        'surface-light': '#333333',
        'surface-variant': '#FFFFFF',
        'on-surface-variant': '#121212',
        "primary-lighten-2": "#6DD3C7",
        "primary-lighten-1": '#42B3A8',
        "primary": '#2F9F84',
        "primary-darken-1": '#36917C',
        'primary-darken-2': '#296F5F',
        "secondary-lighten-2": "#A4D3F5",
        "secondary-lighten-1": '#6EBDF5',
        "secondary": '#69AFF5',
        'secondary-darken-1': '#6983F5',
        "secondary-darken-2": '#3F51B5',
        "error": '#B00020',
        "info": '#2196F3',
        "success": '#4CAF50',
        "warning": '#FB8C00',
    },
}
