import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css' 
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import { VFab } from 'vuetify/labs/VFab'
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import { VBtn } from 'vuetify/components/VBtn'

import defaultLightTheme from './themes/defaultLightTheme'
import defaultDarkTheme from './themes/defaultDarkTheme'

export default createVuetify({
    locale: {
        locale: 'es',
    },
    icons: {
        defaultSet: 'mdi',
    },
    directives,
    theme: {
        options: {
            customProperties: true,
        },
        defaultTheme: localStorage.getItem('theme') || 'light',
        themes: {
            light: defaultLightTheme,
            dark: defaultDarkTheme,
        },
    },
    // Add custom properties
    aliases: {
        VBtnPrimary: VBtn,
    },
    defaults: {
        VBtnPrimary: {
            variant: 'tonal'
        },
    },
    // Add custom components
    components: {
        VFab,
        VTimePicker,
        ...components,
    },
})