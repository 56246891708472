import { createWebHistory, createRouter } from 'vue-router'
import { ADMIN } from '@/util'

const routes = [
    {
        name: 'profile',
        path: '/profile',
        component: () => import('../views/Profile/ProfileBase.vue'),
        meta: {
            requiresAuth: true,
            allowedRoles: [],
            allowedGroups: [],
        },
    },
    {
        name: 'home',
        path: '/home',
        component: () => import('../views/Home/HomeBase.vue'),
        meta: {
            requiresAuth: true,
            allowedRoles: [],
            allowedGroups: [],
        },
    },
    {
        name: 'login',
        path: '/login',
        component: () => import('../views/Login/Login.vue'),
        meta: {
            requiresAuth: false,
            allowedRoles: [],
            allowedGroups: [],
        },
    },
    {
        name: 'landing',
        path: '/',
        component: () => import('../views/Landing/Landing.vue'),
        meta: {
            requiresAuth: false,
            allowedRoles: [],
            allowedGroups: [],
        },
    },
    {
        name: 'lessons',
        path: '/lessons',
        component: () => import('../views/Lessons/LessonsBase.vue'),
        meta: {
            requiresAuth: true,
            allowedRoles: [ADMIN],
            allowedGroups: [],
        },
    },
    {
        name: 'administration',
        path: '/administration',
        component: () => import('../views/Administration/AdministrationBase.vue'),
        meta: {
            requiresAuth: true,
            allowedRoles: [ADMIN],
            allowedGroups: [],
        },
    },
    {
        name: 'payment',
        path: '/payment',
        component: () => import('../views/Payment/PaymentBase.vue'),
        meta: {
            requiresAuth: true,
            allowedRoles: [],
            allowedGroups: [],
        },
    },
    { path: '/:pathMatch(.*)*', redirect: { name: 'login' } },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }    
})
export default router