import { defineStore } from 'pinia'
import {
    getLocalStorageObject,
    getBoolLocalStorage,
    setLocalStorageObject,
} from '@/util'
import { useRouter } from "vue-router";
const router = useRouter()

export const uselogin = defineStore({
    id: 'login',
    state: () => ({
        groups: [],
        userInfo: {},
        role: null,
        apiPaths: {
            login: '/api/token/',
            logout: '/api/logout/',
        },
    }),
    actions: {
        loadUserInfo() {
            const isAuthenticated = getBoolLocalStorage('isAuthenticated')
            if (isAuthenticated) {
                const userInfo = getLocalStorageObject('userInfo')
                if (userInfo) {
                    this.setUserInfo(userInfo)
                }
            }
        },
        async login(username, password) {
            const response = await this.$axios.post(this.apiPaths.login, {
                username,
                password,
            })
            if (response.status === 200) {
                const {data} = response
                this.setUserInfo(data)
                return true
            } else {
                this.clearUserInfo()
                return false
            }
        },
        async logout() {
            try {
                await this.$axios.post(this.apiPaths.logout)
                this.clearUserInfo()
                router.push({ name: 'login' })
            } catch (error) {
                console.error(error)
            }
        },
        setUserInfo(userInfo) {
            localStorage.setItem('isAuthenticated', true)
            this.userInfo = userInfo
            this.role = userInfo?.is_staff ? 'admin' : 'user'
            setLocalStorageObject('userInfo', userInfo)
        },
        clearUserInfo() {
            this.userInfo = {}
            this.role = null
            localStorage.removeItem('userInfo')
            localStorage.removeItem('isAuthenticated')
        },
    },
    getters: {
        getGroups: (state) => state.groups,
        getUserInfo: (state) => state.userInfo,
        getRole: (state) => state.role,
        hasAdminPermissions: (state) => state.role === 'admin',
        hasAccess: (state) => (allowedRoles, allowedGroups) => {

            const userGroups = new Set(state.groups)
            const userRole = state.role
            
            const allowedGroupsSet = new Set(allowedGroups)
            const allowedRolesSet = new Set(allowedRoles)

            return (
                ((allowedRolesSet.size === 0 || allowedRolesSet.has(userRole))) &&
                (allowedGroupsSet.size === 0 || allowedGroupsSet.has(userGroups))
            )
        },
    },
})
