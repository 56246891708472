const ADMIN = 'admin'
const USER = 'user'


const DEFAULT_PAGINATION = {
    page: 1,
    pageSize: 10,
}

const copyClipboard = (text) => {
    navigator.clipboard.writeText(text)
}

const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj))
}

const setCookie = (name, value, minutes) => {
    const date = new Date()
    date.setTime(date.getTime() + minutes * 60 * 1000)
    document.cookie = `${name}=${value}expires=${date.toUTCString()}path=/`
}

const getCookie = (name) => {
    const value = ` ${document.cookie}`
    const parts = value.split(` ${name}=`)
    if (parts.length === 2) return parts.pop().split('').shift()
}

const deleteCookie = (name) => {
    document.cookie = `${name}= expires=Thu, 01 Jan 1970 00:00:01 GMT`
}

const getLocalStorageObject = (key) => {
    return JSON.parse(localStorage.getItem(key))
}

const setLocalStorageObject = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
}

const getBoolLocalStorage = (key) => {
    return localStorage.getItem(key) === 'true'
}

const formatDate = (date) => {
    if (!date) {
        return '';
    }

    let mondate = new Date(date);
    return `${mondate.getDate()}/${mondate.getMonth() + 1
        }/${mondate.getFullYear()}`;
}


const formatDateLarge = (date) => {
    if (!date) {
        return '';
    }

    let mondate = new Date(date);
    let options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'Europe/Madrid',
    };
    try {
        return new Intl.DateTimeFormat('es-ES', options).format(mondate);
    } catch (e) {
        console.log(e);
        return '';
    }
}

const localDate = (date) => {
    if (!date) {
        return null;
    }

    try {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Intl.DateTimeFormat('es-ES', options).format(new Date(date));
    } catch (e) {
        return null;
    }
}

const formatDateToYearMonth = (date) => {
    if (!date) {
        return '';
    }

    const local = localDate(date);
    return `${local.substr(6, 4)}-${local.substr(3, 2)}`;
}

const formatDateYYYYMMDD = (date) => {
    if (!date) {
        return null;
    }
    try {
        const local = localDate(date);
        return `${local.substr(6, 4)}-${local.substr(3, 2)}-${local.substr(0, 2)}`;
    } catch (e) {
        console.log(e);
        return null;
    }
}

const formatDateRangeYYYYMMDD = (date) => {
    if (!date) {
        return null;
    }
    try {
        const start = date[0]
        const end = date[date.length - 1]
        const startLocal = formatDateYYYYMMDD(start);
        const endLocal = formatDateYYYYMMDD(end);
        return [startLocal, endLocal];
    } catch (e) {
        console.log(e);
        return null;
    }
}

const formatDateYYYYMMDDHHMM = (date) => {
    if (!date) {
        return null;
    }
    try {
        const local = localDate(date);
        return `${local.substr(6, 4)}-${local.substr(3, 2)}-${local.substr(0, 2)} ${date.substr(11, 5)}`;
    } catch (e) {
        console.log(e);
        return null;
    }
}

const formatDateYYYYMMDDHHMMFromStrings = (date, time) => {
    if (!date || !time) {
        return null;
    }

    try {
        return `${date} ${time.split(':').slice(0, 2).join(':')}`;
    } catch (e) {
        console.log(e);
        return null;
    }
}

const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
}

async function executeWithRetry(asyncFunc, { timeout = 8000, retries = 2 } = {}) {
    for (let n = 0; n <= retries; n++) {
        const controller = new AbortController();
        const signal = controller.signal;

        const asyncFuncWithTimeout = async () => {
            const timeoutPromise = new Promise((_, reject) =>
                setTimeout(() => reject(new Error('Request timed out')), timeout)
            );

            try {
                const result = await Promise.race([asyncFunc({ signal }), timeoutPromise]);
                return result;
            } finally {
                controller.abort();
            }
        };

        try {
            const data = await asyncFuncWithTimeout();
            return data;
        } catch (err) {
            if (err.message === 'Request timed out') {
                console.error(`Attempt ${n + 1} failed: ${err.message}`);
            } else if (err.name === 'AbortError') {
                console.error(`Attempt ${n + 1} aborted`);
            } else {
                console.error(`Attempt ${n + 1} failed: ${err.message}`);
            }
        }
    }
    console.error(`Failed after ${retries} retries`);
    return null;
}

const compareBiggerStrDate = (a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    if (dateA < dateB) {
        return false;
    }
    if (dateA > dateB) {
        return true;
    }
    return undefined;
}

export {
    ADMIN,
    USER,
    DEFAULT_PAGINATION,
    copyClipboard,
    clone,
    setCookie,
    getCookie,
    deleteCookie,
    getLocalStorageObject,
    setLocalStorageObject,
    getBoolLocalStorage ,
    formatDate,
    formatDateLarge,
    formatDateToYearMonth,
    formatDateYYYYMMDD,
    formatDateRangeYYYYMMDD,
    formatDateYYYYMMDDHHMM,
    formatDateYYYYMMDDHHMMFromStrings,
    isValidDate,
    executeWithRetry,
    compareBiggerStrDate,
}