import { defineStore } from 'pinia'

export const useMain = defineStore({
    id: 'main',
    state: () => ({
        snackBar: {
            show: false,
            text: '<strong>Test</strong>',
            color: '',
            timeout: 6000,
            variant: 'tonal',
            location: ''
        },
    }),
    actions: {
        setSnackBar({ show, text, color, timeout, variant, location }) {
            this.snackBar = {
                show: show || true,
                text: text || '',
                color: color || '',
                timeout: timeout || 6000,
                variant: variant || 'flat',
                location: location || ''
            }
        },
    },
    getters: {
    },
})